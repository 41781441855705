<template>
  <v-timeline-item
    v-if="conversion"
    color="leadActivity"
    icon="mdi-home-search"
    fill-dot
  >
    <v-expansion-panels
      v-if="conversion && property"
      class="expansion-panels"
      accordion
      flat
    >
      <v-expansion-panel>
        <v-expansion-panel-header>
          <v-row
            no-gutters
            align="center"
            class="flex-row justify-center d-flex"
            :class="{'flex-column':$vuetify.breakpoint.smAndDown}"
          >
            <v-col>
              <div class="mb-2 subtitle-1">
                {{ $t('property-request.title-timeline') }}
              </div>
              <div class="grey--text">
                {{ propertyTypeLabel }} • {{ $t('property-request.conversion.labels.in') }} {{ property.location.description }} + {{ property.location.radius }}km • {{ formatPrice(property.minPrice) }} {{ $t('property-request.conversion.labels.until') }} {{ formatPrice(property.maxPrice) }}
              </div>
            </v-col>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col md="4">
              <v-list dense>
                <v-list-item class="px-0">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t('property-request.conversion.labels.contact') }}:
                    </v-list-item-title>
                    <div class="grey--text text--darken-3 body-2">
                      {{ personalData.firstname }} {{ personalData.lastname }}
                    </div>
                    <div class="grey--text text--darken-3 body-2">
                      {{ personalData.phone }}
                    </div>
                  </v-list-item-content>
                </v-list-item>
                <TimelineListItem
                  :label="$t('labels.source')"
                  :value="source"
                />
                <TimelineListItem
                  :label="$t('property-request.conversion.labels.living-situation')"
                  :value="currentLivingSituation"
                />
                <TimelineListItem
                  :label="$t('property-request.conversion.labels.financing')"
                  :value="financing"
                />
                <TimelineListItem
                  :label="$t('property-request.conversion.labels.timeline')"
                  :input-class="isPropertyRequestUrgent?'red--text' :'grey--text'"
                  :value="availableStartDate"
                />
              </v-list>
            </v-col>
            <v-col md="4">
              <v-list dense>
                <TimelineListItem
                  :label="$t('property-request.conversion.labels.looks-for')"
                  :value="propertyTypeLabel"
                />
                <TimelineListItem
                  :label="$t('property-request.conversion.labels.location')"
                  :value="residentialArea"
                />
                <TimelineListItem :label="$t('property-request.conversion.labels.search-radius')">
                  {{ property.location.description }} + {{ property.location.radius }}km
                </TimelineListItem>
                <TimelineListItem :label="$t('property-request.conversion.labels.important-for-contact')">
                  <ul>
                    <li
                      v-for="surrounding of surroundings"
                      :key="surrounding"
                    >
                      {{ $t(surrounding) }}
                    </li>
                  </ul>
                </TimelineListItem>
              </v-list>
            </v-col>
            <v-col md="4">
              <v-list dense>
                <TimelineListItem :label="$t('property-request.conversion.labels.rooms')">
                  {{ $t('property-request.conversion.labels.minimum') }} {{ property.minNumberOfRooms }}
                </TimelineListItem>
                <TimelineListItem :label="$t('property-request.conversion.labels.bathrooms')">
                  {{ $t('property-request.conversion.labels.minimum') }} {{ property.minNumberOfBathrooms }}
                </TimelineListItem>
                <TimelineListItem :label="$t('property-request.conversion.labels.parking-spaces')">
                  {{ $t('property-request.conversion.labels.minimum') }} {{ property.minNumberOfParkingSpaces }}
                </TimelineListItem>
                <TimelineListItem
                  :label="$t('property-request.conversion.labels.outdoor-area')"
                  :value="outdoorArea"
                />
                <TimelineListItem
                  :label="$t('property-request.conversion.labels.accessibility')"
                  :value="accessibility"
                />
              </v-list>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-timeline-item>
</template>

<script>
import currencyMixin from '@/mixins/currency'
import { formatSpaceUnit } from '@/lib/spaceUnit'
import { propertySubTypes, propertyTypes } from '@/lib/propertyTypes'
import {
  AccessibilityLabels,
  AvailableStartDateLabels,
  FinancingLabels,
  LivingSituationLabels,
  OutdoorAreaLabels,
  ResidentialAreaLabels,
  SurroundingsLabels
} from '@/lib/propertyRequest'
import CONVERSION from '../queries/Conversion.gql'
import { conversionSource } from '@/lib/conversionSource'
import TimelineListItem from './TimelineListItem.vue'
import AvailableStartDate from '@/lib/enums/propertyRequest/AvailableStartDate'

export default {
  components: { TimelineListItem },
  mixins: [currencyMixin],
  props: {
    data: { type: Object, default: null },
    leadStatus: { type: String, default: '' }
  },
  data () {
    return {
      conversion: {
        personalData: {},
        propertyRequest: {}
      }
    }
  },
  computed: {
    personalData () {
      return this.conversion?.personalData
    },
    propertyRequest () {
      return this.conversion?.propertyRequest
    },
    property () {
      return this.conversion?.propertyRequest?.property
    },
    propertyTypeLabel () {
      if (this.property?.type) {
        return `${this.$t(propertyTypes[this.property.type])} ${this.$t(propertySubTypes[this.property.subType]) || this.$t('valuation.labels.flexible')}`
      }
      return this.$t('valuation.labels.flexible')
    },
    source () {
      return this.conversion?.source || conversionSource[this.conversion?.origin] || ''
    },
    accessibility () {
      return this.$t(AccessibilityLabels[this.property?.accessibility])
    },
    availableStartDate () {
      return this.$t(AvailableStartDateLabels[this.propertyRequest?.availableStartDate])
    },
    financing () {
      return this.$t(FinancingLabels[this.propertyRequest?.financing]) || this.$t('property-request.conversion.labels.no-answer')
    },
    currentLivingSituation () {
      return this.$t(LivingSituationLabels[this.propertyRequest?.currentLivingSituation]) || this.$t('property-request.conversion.labels.no-answer')
    },
    outdoorArea () {
      return this.$t(OutdoorAreaLabels[this.property?.outdoorArea]) || this.$t('valuation.labels.flexible')
    },
    residentialArea () {
      return this.$t(ResidentialAreaLabels[this.property?.residentialArea]) || this.$t('valuation.labels.flexible')
    },
    surroundings () {
      return this.property.surroundings.map((key) => SurroundingsLabels[key])
    },
    isPropertyRequestUrgent () {
      return this.propertyRequest.availableStartDate === AvailableStartDate.EARLIEST && this.leadStatus === 'new'
    }
  },
  methods: {
    formatSpaceUnit
  },
  apollo: {
    conversion: {
      query: CONVERSION,
      variables () {
        return {
          conversionId: this.data.details[this.data.type].conversionId
        }
      }
    }
  }
}
</script>

<style scoped>
.expansion-panels {
  margin: -24px -24px 0 -24px;
}
</style>
