<template>
  <v-timeline-item
    v-if="showValuation"
    :color="color"
    icon="mdi-home"
    fill-dot
  >
    <v-expansion-panels
      class="expansion-panels"
      accordion
      flat
    >
      <v-expansion-panel>
        <v-expansion-panel-header>
          <v-row
            no-gutters
            align="center"
            class="justify-center d-flex"
            :class="{'flex-column':$vuetify.breakpoint.smAndDown}"
          >
            <v-col md="8">
              <div class="mb-2 subtitle-1">
                {{ title }}
              </div>
              <div
                v-if="isCompleted"
                class="grey--text"
              >
                {{ hasResults ? `${formatPrice(valuation.results.property.value, valuation.results.currency)} •` : '' }}
                {{ valuation.inputs.address.street }} {{ valuation.inputs.address.houseNumber }} •
                {{ valuation.inputs.address.zip }} {{ valuation.inputs.address.city }}
              </div>
              <div
                v-else
                class="grey--text"
              >
                {{ $t('valuation.is-calculating-value') }}
                <v-progress-circular
                  v-if="!isCompleted"
                  :size="15"
                  :width="2"
                  indeterminate
                  color="primary"
                  class="ml-2 mr-2"
                  small
                />
              </div>
            </v-col>
            <v-col
              class="pr-2 text-right grey--text"
              :class="{'text-start mt-2':$vuetify.breakpoint.smAndDown}"
              md="4"
            >
              {{ formatDate(valuation.createdAt) }}
            </v-col>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col md="4">
              <v-list dense>
                <v-list-item
                  v-if="user"
                  class="px-0"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t('valuation.labels.created-by') }}:
                    </v-list-item-title>
                    <div class="grey--text text--darken-3 body-2">
                      {{ user.firstname }} {{ user.lastname }}
                    </div>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item
                  v-if="leadData"
                  class="px-0"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ isCreatedByAgent ? $t('valuation.labels.created-by-agent') : $t('valuation.labels.not-created-by-agent') }}
                    </v-list-item-title>
                    <div class="grey--text text--darken-3 body-2">
                      {{ leadData.firstname }} {{ leadData.lastname }}
                    </div>
                    <div class="grey--text text--darken-3 body-2">
                      {{ leadData.formattedPhone }}
                    </div>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item
                  v-if="hasResults"
                  class="px-0"
                >
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('valuation.labels.average-value') }}:</v-list-item-title>
                    <div class="grey--text text--darken-3 body-2">
                      {{ isCompleted ? formatPrice(valuation.results.property.value, valuation.results.currency) : this.$t('valuation.labels.price-calculating') }}
                    </div>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item
                  v-if="hasResults"
                  class="px-0"
                >
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('valuation.labels.price-range') }}:</v-list-item-title>
                    <div class="grey--text text--darken-3 body-2">
                      {{ isCompleted
                        ? `${formatPrice(valuation.results.property.valueRange.min, valuation.results.currency)} ${$t('valuation.labels.price-until')} ${formatPrice(valuation.results.property.valueRange.max, valuation.results.currency)}`
                        : $t('valuation.labels.price-calculating') }}
                    </div>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item
                  v-if="!isCreatedByAgent"
                  class="px-0"
                >
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('valuation.labels.reason') }}:</v-list-item-title>
                    <div class="grey--text text--darken-3 body-2">
                      <span v-if="reason"> {{ $t(reason) }} </span>
                      <span v-else>{{ $t('valuation.labels.no-reason') }}</span>
                    </div>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item
                  v-if="!isCreatedByAgent"
                  class="px-0"
                >
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('labels.source') }}:</v-list-item-title>
                    <div class="grey--text text--darken-3 body-2">
                      <span v-if="valuationSource">{{ valuationSource }}</span>
                      <span v-else>{{ $t('labels.not-available') }}</span>
                    </div>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item
                  v-if="valuation.provider === 'PRICE_HUBBLE' || valuation.provider === 'SPRENGNETTER' || valuation.provider === 'IMMOSPARROW'"
                  class="px-0"
                >
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('valuation.labels.valuation-quality') }}:</v-list-item-title>
                    <div class="grey--text text--darken-3 body-2">
                      {{ $t(confidence) }}
                    </div>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item
                  v-if="hasResults"
                  class="px-0"
                >
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('valuation.labels.pdf-preview') }}:</v-list-item-title>
                    <div class="grey--text text--darken-3 body-2">
                      <a
                        v-if="isCompletedPDF"
                        :href="previewUri"
                        target="_blank"
                        rel="noopener noreferrer"
                      >{{ $t('valuation.labels.pdf-open') }}</a>
                      <div v-else>
                        <span class="mr-1">{{ $t('valuation.labels.pdf-generating') }}</span>
                        <v-progress-circular
                          :size="15"
                          :width="1"
                          indeterminate
                          color="disabled"
                        />
                      </div>
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col md="4">
              <v-list dense>
                <v-list-item class="px-0">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t('valuation.labels.address') }}:
                    </v-list-item-title>
                    <div class="grey--text text--darken-3 body-2">
                      {{ valuation.inputs.address.street }} {{ valuation.inputs.address.houseNumber }}
                    </div>
                    <div class="grey--text text--darken-3 body-2">
                      {{ valuation.inputs.address.zip }} {{ valuation.inputs.address.city }}
                    </div>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item class="px-0">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t('valuation.labels.object-type') }}:
                    </v-list-item-title>
                    <div class="grey--text text--darken-3 body-2">
                      {{ $t(propertyTypeLabel) }}
                      <span v-if="propertySubTypeLabel">
                        ({{ $t(propertySubTypeLabel) }})
                      </span>
                    </div>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  v-if="valuation.inputs.condition"
                  class="px-0"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t('valuation.labels.condition') }}:
                    </v-list-item-title>
                    <div class="grey--text text--darken-3 body-2">
                      {{ $t(condition) }}
                    </div>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  v-if="valuation.inputs.quality"
                  class="px-0"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t('valuation.labels.quality') }}:
                    </v-list-item-title>
                    <div class="grey--text text--darken-3 body-2">
                      {{ $t(quality) }}
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col md="4">
              <v-list dense>
                <v-list-item class="px-0">
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('valuation.labels.building-year') }}:</v-list-item-title>
                    <div class="grey--text text--darken-3 body-2">
                      {{ valuation.inputs.buildingYear }}
                    </div>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  v-if="valuation.inputs.type === PropertyType.MULTI_FAMILY_HOUSE"
                  class="px-0"
                >
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('valuation.labels.annual-rental-income') }}:</v-list-item-title>
                    <div class="grey--text text--darken-3 body-2">
                      {{ isCompleted ? formatPrice(valuation.inputs.annualRentIncome, valuation.results?.currency) : $t('valuation.labels.income-formatting') }}
                    </div>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  v-if="valuation.inputs.type === PropertyType.MULTI_FAMILY_HOUSE"
                  class="px-0"
                >
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('valuation.labels.units') }}:</v-list-item-title>
                    <div class="grey--text text--darken-3 body-2">
                      {{ valuation.inputs.numberOfUnits }}
                    </div>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  v-else
                  class="px-0"
                >
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('valuation.labels.rooms') }}:</v-list-item-title>
                    <div class="grey--text text--darken-3 body-2">
                      {{ valuation.inputs.numberOfRooms }}
                    </div>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item class="px-0">
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('valuation.labels.living-area') }}:</v-list-item-title>
                    <div class="grey--text text--darken-3 body-2">
                      {{ valuation.inputs.livingArea }} {{ formatSpaceUnit() }}
                    </div>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  v-if="valuation.inputs.type === PropertyType.HOUSE"
                  class="px-0"
                >
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('valuation.labels.land-area') }}:</v-list-item-title>
                    <div class="grey--text text--darken-3 body-2">
                      {{ valuation.inputs.landArea }} {{ formatSpaceUnit() }}
                    </div>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  v-if="valuation.inputs.type === PropertyType.APARTMENT"
                  class="px-0"
                >
                  <v-list-item-content>
                    <v-list-item-title>Garten-/Balkonfläche:</v-list-item-title>
                    <div class="grey--text text--darken-3 body-2">
                      {{ valuation.inputs.balconyArea }} {{ formatSpaceUnit() }}
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
          <div class="justify-end d-flex">
            <v-btn
              small
              outlined
              color="primary"
              :disabled="!isCompleted"
              @click.prevent="downloadValuation"
            >
              Export (.xml)
            </v-btn>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-timeline-item>
</template>

<script>
import currencyMixin from '@/mixins/currency'
import { formatSpaceUnit } from '@/lib/spaceUnit'

import { exportValuation } from '@/lib/export'
import { propertyTypes, propertySubTypes } from '@/lib/propertyTypes'
import { status as ValuationStatus } from '@/lib/valuation'
import { status as ValuationPDFStatus } from '@/lib/valuationPdfStatus'

import { conversionSource } from '@/lib/conversionSource'

import PropertyType from '@/lib/enums/valuation/PropertyType'
import Condition from '@/lib/enums/valuation/Condition'
import Quality from '@/lib/enums/valuation/Quality'
import Reason from '@/lib/enums/valuation/Reason'
import Confidence from '@/lib/enums/valuation/Confidence'

const CONDITION = [
  { value: Condition.RENOVATION_NEEDED, text: 'valuation.condition.renovation-needed' },
  { value: Condition.WELL_MAINTAINED, text: 'valuation.condition.well-maintained' },
  { value: Condition.NEW_OR_RECENTLY_RENOVATED, text: 'valuation.condition.new-or-recently-renovated' }
]

const QUALITY = [
  { value: Quality.SIMPLE, text: 'valuation.quality.simple' },
  { value: Quality.NORMAL, text: 'valuation.quality.normal' },
  { value: Quality.HIGH, text: 'valuation.quality.high' },
  { value: Quality.LUXURY, text: 'valuation.quality.luxury' }
]

export const REASONS = [
  { value: '', text: 'valuation.reasons.none' },
  { value: Reason.PLANNING_TO_SELL, text: 'valuation.reasons.planning-to-sell' },
  { value: Reason.PRICE_TREND, text: 'valuation.reasons.price-trend' },
  { value: Reason.NEED_PROPERTY_BEFORE_SELLING, text: 'valuation.reasons.need-property-before-selling' },
  { value: Reason.HERITAGE, text: 'valuation.reasons.heritage' },
  { value: Reason.BUYING_INTEREST, text: 'valuation.reasons.buying-interest' }
]

const CONFIDENCE = [
  { value: Confidence.LOW, text: 'valuation.confidence.low' },
  { value: Confidence.MEDIUM, text: 'valuation.confidence.medium' },
  { value: Confidence.HIGH, text: 'valuation.confidence.high' }
]

export default {
  mixins: [currencyMixin],
  props: {
    valuation: { type: Object, default: null },
    user: { type: Object, default: null },
    origin: { type: String, default: '' },
    source: { type: String, default: '' },
    leadData: { type: Object, default: null }
  },
  data () {
    return {
      PropertyType
    }
  },
  computed: {
    hasResults () {
      return this.valuation.provider !== 'MANUAL'
    },
    isCreatedByAgent () {
      return this.valuation.createdByAgent
    },
    color () {
      return this.isCreatedByAgent ? 'userActivity' : 'leadActivity'
    },
    showValuation () {
      return this.valuation && this.isCompleted && (this.valuation.userId ? this.user : true)
    },
    propertyTypeLabel () {
      return propertyTypes[this.valuation.inputs.type]
    },
    propertySubTypeLabel () {
      return propertySubTypes[this.valuation.inputs.subtype]
    },
    title () {
      if (this.isCreatedByAgent) return this.$t('valuation.title.is-created-by-agent')
      if (this.hasResults) return this.$t('valuation.title.has-results', { readablePropertyType: this.readablePropertyType() })

      return this.$t('valuation.title.has-results', { readablePropertyType: this.readablePropertyType() })
    },
    condition () {
      return CONDITION.find(obj => { return obj.value === this.valuation.inputs.condition }).text
    },
    quality () {
      return QUALITY.find(obj => { return obj.value === this.valuation.inputs.quality }).text
    },
    reason () {
      return REASONS.find(obj => { return obj.value === this.valuation.reason })?.text || ''
    },
    previewUri () {
      return this.valuation.pdf.file?.cdnUrl
    },
    confidence () {
      return CONFIDENCE.find(obj => obj.value === this.valuation.results?.property.valueConfidence)?.text
    },
    valuationSource () {
      return this.source || conversionSource[this.origin] || ''
    },
    isCompleted () {
      return this.valuation.status === ValuationStatus.COMPLETED
    },
    isCompletedPDF () {
      return this.valuation.pdf.status === ValuationPDFStatus.COMPLETED
    }
  },
  methods: {
    formatSpaceUnit,
    async downloadValuation () {
      await exportValuation({ id: this.valuation.id })
    },
    readablePropertyType () {
      switch (this.valuation.inputs.type) {
        case PropertyType.HOUSE:
          return this.$t('valuation.readable-property-type.house')
        case PropertyType.APARTMENT:
          return this.$t('valuation.readable-property-type.apartment')
        case PropertyType.MULTI_FAMILY_HOUSE:
          return this.$t('valuation.readable-property-type.multi-family-house')
        default:
          return this.$t('valuation.readable-property-type.default')
      }
    }
  }
}
</script>

<style scoped>
.expansion-panels {
  margin: -24px -24px 0 -24px;
}
</style>
